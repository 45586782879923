@font-face {
  font-family: "EuropaGroNr2SH-Ita";
  src: url("../webfonts/EuropaGroNr2SHIta/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHIta/font.woff") format("woff");
}
@font-face {
  font-family: "EuropaGroNr2SH-Rom";
  src: url("../webfonts/EuropaGroNr2SHRom/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHRom/font.woff") format("woff");
}
@font-face {
  font-family: "EuropaGroNr2SH-Ult";
  src: url("../webfonts/EuropaGroNr2SHUlt/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHUlt/font.woff") format("woff");
}
@font-face {
  font-family: "EuropaGroNr2SH-Bol";
  src: url("../webfonts/EuropaGroNr2SHBol/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHBol/font.woff") format("woff");
}
/* line 1, ../sass/common.scss */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 5, ../sass/common.scss */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* line 11, ../sass/common.scss */
body {
  font-family: EuropaGroNr2SH-Rom, sans-serif;
  font-weight: 400;
  font-size: 0;
  background-color: #fff;
  color: #333;
}

/* line 19, ../sass/common.scss */
#root {
  height: 100%;
}

/* line 23, ../sass/common.scss */
a {
  color: inherit;
  text-decoration: none;
}

/* line 28, ../sass/common.scss */
.responsive-video-16-9 {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
/* line 33, ../sass/common.scss */
.responsive-video-16-9 video, .responsive-video-16-9 iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* line 42, ../sass/common.scss */
.close {
  position: absolute;
  width: 27px;
  height: 27px;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

/* line 51, ../sass/common.scss */
h1 {
  font-family: EuropaGroNr2SH-Ult;
  font-size: 48px;
  letter-spacing: 2.4px;
  line-height: 44px;
  text-transform: uppercase;
  color: #B91D19;
  font-weight: normal;
  margin-top: 0;
}

/* line 62, ../sass/common.scss */
h2 {
  font-family: EuropaGroNr2SH-Ult;
  font-size: 32px;
  letter-spacing: 1.6px;
  line-height: 36px;
  text-transform: uppercase;
  color: #B91D19;
  font-weight: normal;
  margin: 30px 0 20px;
}

/* line 73, ../sass/common.scss */
h5 {
  font-size: 16px;
  margin: 0;
}

/* line 78, ../sass/common.scss */
p {
  font-size: 20px;
  line-height: 26px;
}
/* line 82, ../sass/common.scss */
p strong {
  font-weight: normal;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
}
/* line 87, ../sass/common.scss */
p .highlight {
  color: #B91D19;
}
/* line 91, ../sass/common.scss */
p a {
  text-decoration: underline;
}

/* line 96, ../sass/common.scss */
.page {
  padding: 48px 20px;
  min-height: calc(100% - 160px);
}

/* line 101, ../sass/common.scss */
.container {
  position: relative;
  max-width: 714px;
  margin: 0 auto;
}

/* line 107, ../sass/common.scss */
.video {
  max-width: 898px;
  margin: 30px auto 10px;
  background-color: #EDEDED;
}

/* line 113, ../sass/common.scss */
.page-toscani {
  display: flex;
  justify-content: center;
}
@media (max-width: 720px) {
  /* line 113, ../sass/common.scss */
  .page-toscani {
    flex-direction: column;
  }
}
/* line 121, ../sass/common.scss */
.page-toscani .column-left {
  width: 352px;
  margin-right: 16px;
}
@media (max-width: 1023px) {
  /* line 121, ../sass/common.scss */
  .page-toscani .column-left {
    width: 40%;
    margin-right: 2%;
  }
}
@media (max-width: 720px) {
  /* line 121, ../sass/common.scss */
  .page-toscani .column-left {
    width: 100%;
    margin-right: 0;
  }
}
/* line 135, ../sass/common.scss */
.page-toscani .column-left img {
  width: 100%;
  height: auto;
}
/* line 141, ../sass/common.scss */
.page-toscani .column-right {
  width: 438px;
  padding-top: 140px;
}
@media (max-width: 1023px) {
  /* line 141, ../sass/common.scss */
  .page-toscani .column-right {
    width: 58%;
  }
}
@media (max-width: 720px) {
  /* line 141, ../sass/common.scss */
  .page-toscani .column-right {
    width: 100%;
    padding-top: 40px;
  }
}

/* line 156, ../sass/common.scss */
.grecaptcha-badge {
  visibility: hidden;
}

/* line 160, ../sass/common.scss */
input {
  font: inherit;
  color: inherit;
}
/* line 61, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input:-moz-placeholder {
  color: inherit;
}
/* line 64, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input::-moz-placeholder {
  color: inherit;
}
/* line 67, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input:-ms-input-placeholder {
  color: inherit;
}
/* line 56, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input::-webkit-input-placeholder {
  color: inherit;
}

/* line 169, ../sass/common.scss */
.MuiInput-root {
  width: 100%;
}
/* line 172, ../sass/common.scss */
.MuiInput-root .MuiNativeSelect-select:focus {
  background-color: transparent;
}
/* line 176, ../sass/common.scss */
.MuiInput-root .MuiNativeSelect-select.MuiNativeSelect-select {
  padding: 10px 14px;
  font-family: EuropaGroNr2SH-Rom, sans-serif;
  font-size: 20px;
  text-transform: none;
  background-color: #E6E6E6;
}
/* line 184, ../sass/common.scss */
.MuiInput-root .select-icon {
  position: absolute;
  right: 14px;
  width: 14px;
  min-width: 14px;
  margin-right: 4px;
  font-size: 0;
  line-height: 0;
  fill: #B91D19;
}

/* line 196, ../sass/common.scss */
.gallery-wrapper {
  position: relative;
}
/* line 199, ../sass/common.scss */
.gallery-wrapper .gallery {
  position: relative;
  background-color: #97928d;
  width: 1032px;
  height: 688.53px;
  margin: 60px auto 14px;
}
@media (max-width: 1182px) {
  /* line 199, ../sass/common.scss */
  .gallery-wrapper .gallery {
    width: 84.60237vw;
    height: 56.42978vw;
  }
}
@media (max-width: 1023px) {
  /* line 199, ../sass/common.scss */
  .gallery-wrapper .gallery {
    width: calc(100% - 50px);
    height: 58.85417vw;
  }
}
@media (max-width: 720px) {
  /* line 199, ../sass/common.scss */
  .gallery-wrapper .gallery {
    width: 100%;
    height: 59.60267vw;
  }
}
/* line 221, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-items-wrapper {
  overflow: hidden;
  height: 100%;
}
/* line 226, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-items {
  position: relative;
  height: 100%;
}
/* line 231, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
/* line 238, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-item img {
  position: relative;
  width: 100%;
  height: auto;
  pointer-events: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}
/* line 248, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-bullets-wrapper {
  text-align: center;
}
/* line 252, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-bullets-left {
  display: inline-block;
  vertical-align: middle;
  width: 109px;
  height: 7px;
  background-size: 100%;
  margin-right: 10px;
}
/* line 262, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-bullets-right {
  display: inline-block;
  vertical-align: middle;
  width: 109px;
  height: 7px;
  background-size: 100%;
  margin-left: 10px;
}
/* line 272, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-bullets {
  display: inline-block;
  vertical-align: middle;
}
/* line 277, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-bullet {
  width: 9px;
  height: 9px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  background-size: 100%;
  cursor: pointer;
}
/* line 288, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-bullet.current {
  width: 23px;
  height: 23px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px;
  background-size: 100%;
}
/* line 298, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi {
  position: absolute;
  width: 100%;
  height: 50px;
  pointer-events: none;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 720px) {
  /* line 298, ../sass/common.scss */
  .gallery-wrapper .gallery .gallery-navi {
    height: 31px;
  }
}
/* line 310, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi .gallery-prev {
  position: absolute;
  top: 0;
  left: -25px;
  width: 50px;
  height: 50px;
  background-color: #B91D19;
  pointer-events: all;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 720px) {
  /* line 310, ../sass/common.scss */
  .gallery-wrapper .gallery .gallery-navi .gallery-prev {
    width: 25px;
    height: 25px;
    left: 10px;
  }
}
/* line 329, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi .gallery-prev:after {
  content: "";
  margin-right: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 20px 12px 0;
  border-color: transparent #ffffff transparent transparent;
  -moz-transition: border-color 0.25s;
  -o-transition: border-color 0.25s;
  -webkit-transition: border-color 0.25s;
  transition: border-color 0.25s;
}
@media (max-width: 720px) {
  /* line 329, ../sass/common.scss */
  .gallery-wrapper .gallery .gallery-navi .gallery-prev:after {
    border-width: 6px 10px 6px 0;
    margin-right: 2px;
  }
}
/* line 346, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi .gallery-prev:hover:after {
  border-color: transparent #000 transparent transparent;
}
/* line 352, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi .gallery-next {
  position: absolute;
  top: 0;
  right: -25px;
  width: 50px;
  height: 50px;
  background-color: #B91D19;
  pointer-events: all;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 720px) {
  /* line 352, ../sass/common.scss */
  .gallery-wrapper .gallery .gallery-navi .gallery-next {
    width: 25px;
    height: 25px;
    right: 10px;
  }
}
/* line 371, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi .gallery-next:after {
  content: "";
  margin-left: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent #ffffff;
  -moz-transition: border-color 0.25s;
  -o-transition: border-color 0.25s;
  -webkit-transition: border-color 0.25s;
  transition: border-color 0.25s;
}
@media (max-width: 720px) {
  /* line 371, ../sass/common.scss */
  .gallery-wrapper .gallery .gallery-navi .gallery-next:after {
    border-width: 6px 0 6px 10px;
    margin-left: 2px;
  }
}
/* line 388, ../sass/common.scss */
.gallery-wrapper .gallery .gallery-navi .gallery-next:hover:after {
  border-color: transparent transparent transparent #000;
}

/* line 397, ../sass/common.scss */
.location-link {
  margin-top: 30px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
/* line 403, ../sass/common.scss */
.location-link img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

/* line 1, ../sass/header.scss */
.header {
  height: 80px;
  background-color: #B91D19;
}
/* line 5, ../sass/header.scss */
.header .inner {
  height: 100%;
  max-width: 1142px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
@media (max-width: 1182px) {
  /* line 5, ../sass/header.scss */
  .header .inner {
    padding: 0 30px;
  }
}
/* line 16, ../sass/header.scss */
.header .inner .navi-item {
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
@media (max-width: 1182px) {
  /* line 16, ../sass/header.scss */
  .header .inner .navi-item {
    display: none;
  }
}
/* line 23, ../sass/header.scss */
.header .inner .navi-item:hover {
  color: #000;
}
/* line 28, ../sass/header.scss */
.header .inner .navi-item.home:hover {
  color: #fff;
}
@media (max-width: 1182px) {
  /* line 27, ../sass/header.scss */
  .header .inner .navi-item.home {
    display: block;
  }
}
@media (max-width: 1182px) {
  /* line 37, ../sass/header.scss */
  .header .inner .lang {
    display: none;
  }
}
/* line 42, ../sass/header.scss */
.header .inner .lang .lang-item {
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 45, ../sass/header.scss */
.header .inner .lang .lang-item:hover {
  color: #000;
}
/* line 51, ../sass/header.scss */
.header .inner .burger {
  width: 24px;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  display: none;
  cursor: pointer;
}
@media (max-width: 1182px) {
  /* line 51, ../sass/header.scss */
  .header .inner .burger {
    display: flex;
  }
}
/* line 64, ../sass/header.scss */
.header .inner .burger .burger-line {
  height: 3px;
  background-color: #fff;
}

/* line 72, ../sass/header.scss */
.navi-item {
  font-size: 24px;
  letter-spacing: 1.2px;
  color: #fff;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  margin-right: 30px;
  text-transform: uppercase;
}
/* line 80, ../sass/header.scss */
.navi-item.home {
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: 0.7px !important;
  text-align: center;
}
/* line 86, ../sass/header.scss */
.navi-item.home span {
  color: #000;
}

/* line 92, ../sass/header.scss */
.lang {
  margin-left: auto;
  display: flex;
}
/* line 96, ../sass/header.scss */
.lang .lang-item {
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
/* line 104, ../sass/header.scss */
.lang .lang-item.inactive {
  opacity: .5;
  pointer-events: none;
}

/* line 111, ../sass/header.scss */
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #B91D19;
  padding: 30px;
  z-index: 200;
}
/* line 121, ../sass/header.scss */
.menu .close {
  fill: #fff;
}
/* line 125, ../sass/header.scss */
.menu .navi-item {
  display: table;
  font-size: 28px;
  letter-spacing: 1.4px;
  margin-bottom: 26px;
  margin-right: 0;
}
/* line 132, ../sass/header.scss */
.menu .navi-item.home {
  margin-bottom: 63px;
}
/* line 137, ../sass/header.scss */
.menu .legal {
  margin: 46px 0;
}
/* line 140, ../sass/header.scss */
.menu .legal .navi-item {
  font-size: 16px;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
  margin-right: 0;
}
/* line 148, ../sass/header.scss */
.menu .lang {
  margin-left: 0;
}
/* line 151, ../sass/header.scss */
.menu .lang .lang-item {
  font-size: 16px;
  letter-spacing: 0.8px;
  margin-left: 0;
  margin-right: 10px;
}

/* line 1, ../sass/footer.scss */
.footer {
  height: 80px;
  background-color: #B91D19;
}
/* line 5, ../sass/footer.scss */
.footer .inner {
  height: 100%;
  max-width: 1122px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1182px) {
  /* line 5, ../sass/footer.scss */
  .footer .inner {
    padding: 0 20px;
  }
}
@media (max-width: 720px) {
  /* line 5, ../sass/footer.scss */
  .footer .inner {
    justify-content: center;
  }
}
/* line 21, ../sass/footer.scss */
.footer .inner .logo {
  width: 147px;
  height: auto;
  margin-right: 10px;
}
@media (max-width: 720px) {
  /* line 21, ../sass/footer.scss */
  .footer .inner .logo {
    width: 29.4vw;
    padding-left: 2.66667vw;
  }
}
/* line 32, ../sass/footer.scss */
.footer .inner .studiozx {
  width: 140px;
  height: auto;
}
@media (max-width: 720px) {
  /* line 32, ../sass/footer.scss */
  .footer .inner .studiozx {
    width: 28vw;
  }
}
/* line 41, ../sass/footer.scss */
.footer .inner .cooperation {
  font-size: 11px;
  letter-spacing: 0.55px;
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 720px) {
  /* line 41, ../sass/footer.scss */
  .footer .inner .cooperation {
    font-size: 2.66667vw;
    letter-spacing: 0.13333vw;
  }
}
/* line 53, ../sass/footer.scss */
.footer .inner .navi-item {
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #fff;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  margin-left: auto;
  text-transform: uppercase;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 62, ../sass/footer.scss */
.footer .inner .navi-item:hover {
  color: #000;
}
/* line 66, ../sass/footer.scss */
.footer .inner .navi-item.last {
  margin-left: 10px;
}
@media (max-width: 1182px) {
  /* line 53, ../sass/footer.scss */
  .footer .inner .navi-item {
    display: none;
  }
}

/* line 1, ../sass/intro.scss */
.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* line 14, ../sass/intro.scss */
.intro .ot {
  font-size: 36px;
  letter-spacing: 1.08px;
  color: #000;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  text-transform: uppercase;
  margin-bottom: 16px;
}
@media (max-width: 720px) {
  /* line 14, ../sass/intro.scss */
  .intro .ot {
    font-size: 5.53846vw;
    letter-spacing: 0.16615vw;
    margin-bottom: 2.46154vw;
  }
}
/* line 29, ../sass/intro.scss */
.intro .title {
  font-size: 73px;
  line-height: 77px;
  letter-spacing: 3.65px;
  color: #B91D19;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 720px) {
  /* line 29, ../sass/intro.scss */
  .intro .title {
    font-size: 11.23077vw;
    line-height: 11.84615vw;
    letter-spacing: 0.56154vw;
  }
}
/* line 44, ../sass/intro.scss */
.intro .title span {
  display: block;
  font-size: 151px;
  line-height: 140px;
  letter-spacing: 7.55px;
  color: #000;
}
@media (max-width: 720px) {
  /* line 44, ../sass/intro.scss */
  .intro .title span {
    font-size: 23.23077vw;
    line-height: 21.53846vw;
    letter-spacing: 1.16154vw;
  }
}
/* line 59, ../sass/intro.scss */
.intro .logos {
  display: flex;
  align-items: center;
  margin-top: 36px;
}
@media (max-width: 720px) {
  /* line 59, ../sass/intro.scss */
  .intro .logos {
    margin-top: 5.53846vw;
  }
}
/* line 68, ../sass/intro.scss */
.intro .logos .logo {
  width: 75px;
  height: auto;
  margin-right: 30px;
}
@media (max-width: 720px) {
  /* line 68, ../sass/intro.scss */
  .intro .logos .logo {
    width: 11.53846vw;
    margin-right: 4.61538vw;
  }
}
/* line 79, ../sass/intro.scss */
.intro .logos .zx {
  width: 131px;
  height: auto;
}
@media (max-width: 720px) {
  /* line 79, ../sass/intro.scss */
  .intro .logos .zx {
    width: 20.15385vw;
  }
}

/* line 1, ../sass/home.scss */
.page-home {
  display: flex;
  flex-wrap: wrap;
}
/* line 5, ../sass/home.scss */
.page-home img {
  width: 20%;
  height: auto;
}
@media (max-width: 1023px) {
  /* line 5, ../sass/home.scss */
  .page-home img {
    width: 33.33333%;
  }
  /* line 12, ../sass/home.scss */
  .page-home img:nth-child(1) {
    order: -5;
  }
  /* line 16, ../sass/home.scss */
  .page-home img:nth-child(2) {
    order: -4;
  }
  /* line 20, ../sass/home.scss */
  .page-home img:nth-child(3) {
    order: -3;
  }
  /* line 24, ../sass/home.scss */
  .page-home img:nth-child(4) {
    order: -2;
  }
  /* line 28, ../sass/home.scss */
  .page-home img:nth-child(18) {
    display: none;
  }
  /* line 32, ../sass/home.scss */
  .page-home img:nth-child(19) {
    display: none;
  }
}
/* line 38, ../sass/home.scss */
.page-home .entry {
  position: relative;
  width: 40%;
  padding: 1.46413vw;
  background-color: #B91D19;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 3.51391vw;
  letter-spacing: 0.1757vw;
  color: #fff;
  font-family: "EuropaGroNr2SH-Ult";
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
@media (max-width: 1023px) {
  /* line 38, ../sass/home.scss */
  .page-home .entry {
    width: 66.66666%;
    padding: 5.33333vw;
    font-size: 5.86667vw;
    letter-spacing: 0.29333vw;
    order: -1;
  }
}
/* line 62, ../sass/home.scss */
.page-home .entry span {
  display: block;
}
/* line 66, ../sass/home.scss */
.page-home .entry .triangle {
  position: absolute;
  right: 1.46413vw;
  bottom: 1.46413vw;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.20791vw 0 1.20791vw 2.04978vw;
  border-color: transparent transparent transparent #ffffff;
  -moz-transition: border-color 0.25s linear;
  -o-transition: border-color 0.25s linear;
  -webkit-transition: border-color 0.25s linear;
  transition: border-color 0.25s linear;
}
@media (max-width: 1023px) {
  /* line 66, ../sass/home.scss */
  .page-home .entry .triangle {
    right: 5.33333vw;
    bottom: 5.33333vw;
    border-width: 2vw 0 2vw 3.2vw;
  }
}
/* line 84, ../sass/home.scss */
.page-home .entry:hover {
  color: #000;
}
/* line 87, ../sass/home.scss */
.page-home .entry:hover .triangle {
  border-color: transparent transparent transparent #000;
}
/* line 93, ../sass/home.scss */
.page-home .stoerer-wrapper {
  position: absolute;
  left: 20%;
  width: 60%;
  height: 27.81845vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
/* line 102, ../sass/home.scss */
.page-home .stoerer-wrapper .stoerer {
  font-size: 1.75695vw;
  line-height: 2.19619vw;
  background-color: #fff;
  text-align: center;
  padding: 1.90337vw 3.22108vw;
}
/* line 109, ../sass/home.scss */
.page-home .stoerer-wrapper .stoerer span {
  color: #B91D19;
  font-family: "EuropaGroNr2SH-Ult";
  text-transform: uppercase;
}
/* line 115, ../sass/home.scss */
.page-home .stoerer-wrapper .stoerer strong {
  font-family: "EuropaGroNr2SH-Ult";
  text-transform: uppercase;
  font-weight: normal;
}
/* line 121, ../sass/home.scss */
.page-home .stoerer-wrapper .stoerer a {
  text-decoration: underline;
}

/* line 1, ../sass/bias-o-mat.scss */
.page-bias-o-mat {
  position: relative;
}
/* line 4, ../sass/bias-o-mat.scss */
.page-bias-o-mat .background-bias-o-mat {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100% - 160px);
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  opacity: .1;
}
/* line 16, ../sass/bias-o-mat.scss */
.page-bias-o-mat .background-bias-o-mat img {
  width: 20%;
  height: auto;
}
@media (max-width: 1023px) {
  /* line 16, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img {
    width: 33.33333%;
  }
  /* line 23, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(1) {
    order: -5;
  }
  /* line 27, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(2) {
    order: -4;
  }
  /* line 31, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(3) {
    order: -3;
  }
  /* line 35, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(4) {
    order: -2;
  }
  /* line 39, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(18) {
    display: none;
  }
  /* line 43, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(19) {
    display: none;
  }
}
/* line 50, ../sass/bias-o-mat.scss */
.page-bias-o-mat a {
  display: table;
  background-color: #B91D19;
  font-family: EuropaGroNr2SH-Ult;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  line-height: 56px;
  text-transform: uppercase;
  padding: 0 20px;
  margin-bottom: 40px;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 63, ../sass/bias-o-mat.scss */
.page-bias-o-mat a:hover {
  color: #000;
}

/* line 70, ../sass/bias-o-mat.scss */
.page-test .columns {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 720px) {
  /* line 70, ../sass/bias-o-mat.scss */
  .page-test .columns {
    flex-direction: column;
  }
}
/* line 78, ../sass/bias-o-mat.scss */
.page-test .columns .column-left {
  width: 345px;
}
@media (max-width: 720px) {
  /* line 78, ../sass/bias-o-mat.scss */
  .page-test .columns .column-left {
    width: 100%;
  }
}
/* line 85, ../sass/bias-o-mat.scss */
.page-test .columns .column-left img {
  width: 100%;
  height: auto;
}
/* line 91, ../sass/bias-o-mat.scss */
.page-test .columns .column-right {
  width: 346px;
}
@media (max-width: 720px) {
  /* line 91, ../sass/bias-o-mat.scss */
  .page-test .columns .column-right {
    width: 100%;
    margin-top: 40px;
  }
}
/* line 99, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container {
  margin-bottom: 30px;
}
/* line 102, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .question {
  font-size: 24px;
  line-height: 28px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  margin-bottom: 10px;
}
/* line 109, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .buttons {
  display: flex;
  justify-content: space-between;
}
/* line 113, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .buttons .button {
  width: 162px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #B91D19;
  color: #B91D19;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
/* line 129, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .buttons .button:hover {
  color: #fff;
  background-color: #B91D19;
}
/* line 134, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .buttons .button.active {
  color: #fff;
  background-color: #B91D19;
}
/* line 139, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .buttons .button.inactive {
  opacity: .5;
}
/* line 143, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .buttons .button.disabled {
  pointer-events: none;
}
/* line 149, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result {
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* line 155, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .is-correct {
  display: flex;
  justify-content: center;
}
/* line 159, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .is-correct img {
  width: 32px;
  height: auto;
  margin-right: 10px;
}
/* line 165, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .is-correct .is-correct-label {
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
}
/* line 171, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .is-correct .is-correct-label.correct {
  color: #19B919;
}
/* line 175, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .is-correct .is-correct-label.wrong {
  color: #B91D19;
}
/* line 181, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .others-label {
  margin-top: 10px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 16px;
}
/* line 187, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .others-result {
  width: 100%;
  margin-top: 10px;
}
/* line 191, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .others-result .bar {
  position: relative;
  height: 30px;
  border: 3px solid #B91D19;
  border-radius: 19px;
  overflow: hidden;
}
/* line 198, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .others-result .bar .inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  background-color: #B91D19;
}
/* line 206, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .others-result .bar .yes {
  position: absolute;
  left: 7px;
  top: 4px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 16px;
}
/* line 214, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .question-container .result .others-result .bar .no {
  position: absolute;
  right: 7px;
  top: 4px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 16px;
}
/* line 226, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .submit {
  width: 346px;
  height: 56px;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  pointer-events: none;
  opacity: .5;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
@media (max-width: 720px) {
  /* line 226, ../sass/bias-o-mat.scss */
  .page-test .columns .column-right .submit {
    width: 100%;
  }
}
/* line 248, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .submit:hover {
  color: #000;
}
/* line 252, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .submit.active {
  pointer-events: auto;
  opacity: 1;
}
/* line 258, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .next {
  width: 346px;
  height: 56px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
@media (max-width: 720px) {
  /* line 258, ../sass/bias-o-mat.scss */
  .page-test .columns .column-right .next {
    width: 100%;
  }
}
/* line 278, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .next .triangle {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 12px;
  margin-left: 14px;
  border-color: transparent transparent transparent #ffffff;
  -moz-transition: border-color 0.25s linear;
  -o-transition: border-color 0.25s linear;
  -webkit-transition: border-color 0.25s linear;
  transition: border-color 0.25s linear;
}
/* line 289, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .next:hover {
  color: #000;
}
/* line 292, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .next:hover .triangle {
  border-color: transparent transparent transparent #000;
}
/* line 298, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .mitmachen {
  font-size: 20px;
  line-height: 26px;
  margin: 60px 0 15px;
}
/* line 304, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .cta {
  width: 296px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #B91D19;
  color: #B91D19;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
/* line 320, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .cta:hover {
  color: #fff;
  background-color: #B91D19;
}
/* line 326, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .problem {
  display: table;
  font-size: 14px;
  letter-spacing: 0.7px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  margin: 37px 0 15px;
  text-transform: uppercase;
  color: #BFBFBF;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 336, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .problem:hover {
  color: #000;
}

/* line 344, ../sass/bias-o-mat.scss */
.page-mitmachen {
  padding-bottom: 140px;
}
/* line 346, ../sass/bias-o-mat.scss */
.page-mitmachen .columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 720px) {
  /* line 346, ../sass/bias-o-mat.scss */
  .page-mitmachen .columns {
    flex-direction: column;
  }
}
/* line 355, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-left {
  width: 345px;
}
@media (max-width: 720px) {
  /* line 355, ../sass/bias-o-mat.scss */
  .page-mitmachen .columns .column-left {
    width: 100%;
  }
}
/* line 362, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-left .dropzone {
  flex: 1;
  height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #E6E6E6;
  outline: none;
  cursor: pointer;
  -moz-transition: color 0.25s;
  -o-transition: color 0.25s;
  -webkit-transition: color 0.25s;
  transition: color 0.25s;
}
/* line 376, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-left .dropzone p {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  max-width: 280px;
}
/* line 382, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-left .dropzone p.warning {
  color: #B91D19;
}
/* line 387, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-left .dropzone img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
/* line 395, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-right {
  width: 346px;
}
@media (max-width: 720px) {
  /* line 395, ../sass/bias-o-mat.scss */
  .page-mitmachen .columns .column-right {
    width: 100%;
    margin-top: 40px;
  }
}
/* line 403, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-right input {
  width: 100%;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 24px;
  border: none;
  outline: none;
  border-bottom: 3px solid #B91D19;
  padding-bottom: 10px;
  margin: 0 0 30px;
  border-radius: 0;
}
/* line 415, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-right .warning {
  font-size: 24px;
  color: #B91D19;
  margin: -20px 0 30px;
}
/* line 421, ../sass/bias-o-mat.scss */
.page-mitmachen .columns .column-right .asterisk {
  font-size: 16px;
  margin-top: -20px;
}
/* line 428, ../sass/bias-o-mat.scss */
.page-mitmachen .question {
  margin-top: 38px;
}
/* line 431, ../sass/bias-o-mat.scss */
.page-mitmachen .question .question-label {
  font-size: 24px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  margin-bottom: 10px;
}
/* line 437, ../sass/bias-o-mat.scss */
.page-mitmachen .question .answer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
/* line 442, ../sass/bias-o-mat.scss */
.page-mitmachen .question .answer .answer-label {
  font-size: 24px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
}
/* line 447, ../sass/bias-o-mat.scss */
.page-mitmachen .question .answer .buttons {
  display: flex;
  justify-content: flex-start;
  margin-left: 4px;
}
/* line 452, ../sass/bias-o-mat.scss */
.page-mitmachen .question .answer .buttons .button {
  margin-left: 10px;
  width: 80px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #B91D19;
  color: #B91D19;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
/* line 469, ../sass/bias-o-mat.scss */
.page-mitmachen .question .answer .buttons .button:hover {
  color: #fff;
  background-color: #B91D19;
}
/* line 474, ../sass/bias-o-mat.scss */
.page-mitmachen .question .answer .buttons .button.active {
  color: #fff;
  background-color: #B91D19;
}
/* line 483, ../sass/bias-o-mat.scss */
.page-mitmachen .ds {
  margin-top: 38px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}
@media (max-width: 720px) {
  /* line 483, ../sass/bias-o-mat.scss */
  .page-mitmachen .ds {
    align-items: flex-start;
  }
}
/* line 495, ../sass/bias-o-mat.scss */
.page-mitmachen .ds .checkbox {
  width: 32px;
  height: 32px;
  border: 3px solid #B91D19;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
@media (max-width: 720px) {
  /* line 495, ../sass/bias-o-mat.scss */
  .page-mitmachen .ds .checkbox {
    min-width: 32px;
  }
}
/* line 508, ../sass/bias-o-mat.scss */
.page-mitmachen .ds .checkbox .inner {
  width: 20px;
  height: 20px;
  background-color: #B91D19;
}
/* line 515, ../sass/bias-o-mat.scss */
.page-mitmachen .ds a {
  text-decoration: underline;
}
/* line 520, ../sass/bias-o-mat.scss */
.page-mitmachen .recaptcha-label {
  margin-top: 38px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.7px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  text-transform: uppercase;
  color: #BFBFBF;
}
/* line 530, ../sass/bias-o-mat.scss */
.page-mitmachen .recaptcha-label a {
  text-decoration: underline;
}
/* line 535, ../sass/bias-o-mat.scss */
.page-mitmachen .submit {
  margin: 40px auto 0;
  width: 346px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
@media (max-width: 720px) {
  /* line 535, ../sass/bias-o-mat.scss */
  .page-mitmachen .submit {
    width: 100%;
  }
}
/* line 555, ../sass/bias-o-mat.scss */
.page-mitmachen .submit:hover {
  color: #000;
}
/* line 560, ../sass/bias-o-mat.scss */
.page-mitmachen .progress-bar-container {
  max-width: 278px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px auto 0;
}
/* line 567, ../sass/bias-o-mat.scss */
.page-mitmachen .progress-bar-container .progress-bar {
  width: 100%;
  height: 16px;
  padding: 2px;
  border: 3px solid #B91D19;
}
/* line 573, ../sass/bias-o-mat.scss */
.page-mitmachen .progress-bar-container .progress-bar .progress-bar-inner {
  width: 0;
  height: 6px;
  background-color: #B91D19;
}
/* line 580, ../sass/bias-o-mat.scss */
.page-mitmachen .progress-bar-container .progress-bar-text {
  color: #B91D19;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 18px;
  width: 64px;
  min-width: 64px;
  text-align: right;
}
/* line 590, ../sass/bias-o-mat.scss */
.page-mitmachen .test {
  margin: 40px auto 0;
  width: 242px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 606, ../sass/bias-o-mat.scss */
.page-mitmachen .test:hover {
  color: #000;
}

/* line 612, ../sass/bias-o-mat.scss */
.page-problem {
  padding-bottom: 140px;
}
/* line 617, ../sass/bias-o-mat.scss */
.page-problem form .fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* line 622, ../sass/bias-o-mat.scss */
.page-problem form .fields .field {
  width: 48.5%;
}
/* line 625, ../sass/bias-o-mat.scss */
.page-problem form .fields .field input {
  width: 100%;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 24px;
  border: none;
  outline: none;
  border-bottom: 3px solid #B91D19;
  padding-bottom: 10px;
  margin: 0 0 30px;
  border-radius: 0;
}
/* line 637, ../sass/bias-o-mat.scss */
.page-problem form .fields .field .warning {
  margin: -20px 0 30px;
}
/* line 643, ../sass/bias-o-mat.scss */
.page-problem form .warning {
  font-size: 24px;
  color: #B91D19;
  margin: -5px 0 30px;
}
/* line 649, ../sass/bias-o-mat.scss */
.page-problem form textarea {
  width: 100%;
  height: 200px;
  font-family: EuropaGroNr2SH-Rom, sans-serif;
  font-size: 20px;
  line-height: 26px;
  border: none;
  outline: none;
  border: 3px solid #B91D19;
  padding: 17px;
  margin: 40px 0 15px;
  border-radius: 0;
}
/* line 663, ../sass/bias-o-mat.scss */
.page-problem form .id {
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 24px;
  color: #A5A5A5;
}
/* line 669, ../sass/bias-o-mat.scss */
.page-problem form .ds {
  margin-top: 38px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}
/* line 677, ../sass/bias-o-mat.scss */
.page-problem form .ds .checkbox {
  min-width: 32px;
  width: 32px;
  height: 32px;
  border: 3px solid #B91D19;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
/* line 687, ../sass/bias-o-mat.scss */
.page-problem form .ds .checkbox .inner {
  width: 20px;
  height: 20px;
  background-color: #B91D19;
}
/* line 694, ../sass/bias-o-mat.scss */
.page-problem form .ds a {
  text-decoration: underline;
}
/* line 699, ../sass/bias-o-mat.scss */
.page-problem form .recaptcha-label {
  margin-top: 38px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.7px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  text-transform: uppercase;
  color: #BFBFBF;
}
/* line 709, ../sass/bias-o-mat.scss */
.page-problem form .recaptcha-label a {
  text-decoration: underline;
}
/* line 714, ../sass/bias-o-mat.scss */
.page-problem form .submit {
  margin: 40px auto 0;
  width: 346px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 730, ../sass/bias-o-mat.scss */
.page-problem form .submit:hover {
  color: #000;
}
/* line 736, ../sass/bias-o-mat.scss */
.page-problem .test {
  margin: 40px auto 0;
  width: 242px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 752, ../sass/bias-o-mat.scss */
.page-problem .test:hover {
  color: #000;
}
